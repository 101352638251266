<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="34" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    stroke="currentColor" 
    stroke-width="2" 
    stroke-linecap="round" 
    stroke-linejoin="round" 
    class="feather feather-more-horizontal"
  >
    <circle cx="2" cy="12" r="1"></circle>
    <circle cx="12" cy="12" r="1"></circle>
    <circle cx="22" cy="12" r="1"></circle>
  </svg>
</template>

